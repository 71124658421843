<template>
    <div>
        <Navbar v-if="!isWidget" :key="$i18n.locale" />
        <ProgressLoader class="pt-3" v-if="isLoading && !isSetup" />
        <v-main v-else>
            <v-container class="global-container">
                <v-row class="fill-height">
                    <v-col>
                        <v-fade-transition>
                            <Alert
                                v-show="hasAlert"
                                :type="alertState.type"
                                :message="alertState.message"
                                class="py-5"
                            />
                        </v-fade-transition>
                        <transition name="fade">
                            <router-view></router-view>
                        </transition>
                    </v-col>
                </v-row>
            </v-container>
            <WhatsAppButton
                v-if="
                    whatsAppNumber &&
                    $vuetify.breakpoint.mdAndUp &&
                    !iBrandedApp
                "
                :whatsAppNumber="whatsAppNumber"
            />
        </v-main>
    </div>
</template>
<script>
import Navbar from '@/components/navbar/Navbar'
import WhatsAppButton from '@/components/shared/WhatsAppButton.vue'
import ProgressLoader from '@/components/shared/ProgressLoader'
import Alert from '@/components/shared/Alert'
import { mapGetters, mapActions } from 'vuex'
import {
    ALERT_MODULE_NAME,
    STUDIO_MODULE_NAME,
    AUTHENTICATION_MODULE_NAME,
    CUSTOM_FIELDS_MODULE_NAME,
} from '@/store/moduleNames'
import { WIDGET_ROUTE } from '@/constants'
import addTokenToDb from '@/util/notifications/tokenUpdater'
import createUserAndAddAsMember from '@/util/cloudFunctions/authentication'
import {
    WEBSITE_WIDGET_APP_SOURCE,
    BOOKING_WEBSITE_APP_SOURCE,
    BRANDED_APP_APP_SOURCE,
} from '@/util/constants'

export default {
    components: {
        ProgressLoader,
        Navbar,
        Alert,
        WhatsAppButton,
    },
    computed: {
        ...mapGetters({
            alertState: `${ALERT_MODULE_NAME}/getAlertState`,
            hasAlert: `${ALERT_MODULE_NAME}/hasAlert`,
            isLoading: `${STUDIO_MODULE_NAME}/isLoading`,
            isInvalid: `${STUDIO_MODULE_NAME}/isInvalid`,
            studio: `${STUDIO_MODULE_NAME}/getStudio`,
            user: `${AUTHENTICATION_MODULE_NAME}/getUser`,
            isBrandedApp: `${AUTHENTICATION_MODULE_NAME}/isBrandedApp`,
        }),
        isWidget() {
            return this.$route.hash === WIDGET_ROUTE
        },
        whatsAppNumber() {
            return this.studio.whatsAppNumber
        },
        appSource() {
            if (this.isBrandedApp) {
                return BRANDED_APP_APP_SOURCE
            }
            if (this.isWidget) {
                return WEBSITE_WIDGET_APP_SOURCE
            }
            return BOOKING_WEBSITE_APP_SOURCE
        },
        userAndStudio() {
            return {
                user: this.user,
                studio: this.studio,
            }
        },
    },
    watch: {
        user: {
            handler() {
                this.logToken()
                this.fetchHasUnfilledCustomFields()
            },
        },
        $route(to, from) {
            // react to route changes...
            const oldWebAppRoute = from.params.studioRoute
            const newWebAppRoute = to.params.studioRoute
            if (oldWebAppRoute !== newWebAppRoute) {
                this.setCurrentStudio()
                this.fetchHasUnfilledCustomFields()
            }
        },
        isInvalid(value) {
            if (value) {
                this.$router.push('/404')
            }
        },
        async userAndStudio() {
            await createUserAndAddAsMember(
                this.studio.id,
                this.user.fullName,
                this.user.email,
                this.user.username,
                this.user.dob,
                this.user.personalDetails?.mobileNum,
                null,
                this.user.country,
                this.user.id,
                null,
                this.appSource
            )
        },
    },
    methods: {
        ...mapActions({
            setStudioAction: `${STUDIO_MODULE_NAME}/setStudioAction`,
            setBrandedApp: `${AUTHENTICATION_MODULE_NAME}/setBrandedApp`,
            fetchHasUnfilledCustomFieldsAction: `${CUSTOM_FIELDS_MODULE_NAME}/fetchHasUnfilledCustomFieldsAction`,
        }),
        async setCurrentStudio() {
            const webAppRoute = this.$route.params.studioRoute
            await this.setStudioAction(webAppRoute)
            this.setLocale()
            this.initAppearance()
        },
        setLocale() {
            const studio = this.studio
            if (studio) {
                const lang = studio.language
                if (lang) {
                    this.$i18n.locale = lang
                    this.$vuetify.lang.current = lang
                } else {
                    this.$i18n.locale = 'en'
                }
                if (lang === 'zh') {
                    this.$vuetify.lang.current = 'zhHans'
                }
            }
        },
        logToken() {
            const token = this.token
            const studio = this.studio
            if (token !== undefined) {
                this.setBrandedApp()
            }
            if (token && this.user && studio) {
                const studioId = studio.studioId
                addTokenToDb(token, studioId, this.user, this.brandedServer)
            }
        },
        initAppearance() {
            const primaryColor = this.studio.primaryColor ?? this.primaryColor
            const dilutedPrimaryColor = this.studio.primaryColor
                ? this.studio.primaryColor + '2e'
                : this.dilutedPrimaryColor
            this.$vuetify.theme.themes.light.primary = primaryColor
            this.$vuetify.theme.themes.light.dilutedPrimary =
                dilutedPrimaryColor
            this.$vuetify.theme.themes.dark.primary = primaryColor
            this.$vuetify.theme.themes.dark.dilutedPrimary = dilutedPrimaryColor
            this.$vuetify.theme.dark = this.studio?.isWebAppDarkMode || false
            if (this.studio.webAppFontColor) {
                const theme = this.$vuetify.theme.dark ? 'dark' : 'light'
                this.$vuetify.theme.themes[theme].appFontColor.base =
                    this.studio.webAppFontColor
            }
            this.isSetup = true
        },
        fetchHasUnfilledCustomFields() {
            if (this.user && this.studio) {
                this.fetchHasUnfilledCustomFieldsAction({
                    userId: this.user.id,
                    studioId: this.studio.id,
                })
            }
        },
    },
    data() {
        return {
            isSetup: false,
            token: undefined,
            brandedServer: undefined,
            primaryColor: '#8924ff',
            dilutedPrimaryColor: '#8924ff2e',
        }
    },
    async created() {
        const queryParams = this.$route.query
        const token = queryParams.token
        const brandedServer = queryParams.brandedServer
        if (token) {
            this.token = token
            if (brandedServer) {
                this.brandedServer = brandedServer
            }
        }

        await this.setCurrentStudio()
        this.logToken()
        this.fetchHasUnfilledCustomFields()
    },
    destroyed() {
        this.$vuetify.theme.dark = false
        this.$vuetify.theme.themes.light.appFontColor.base = '#000000'
        this.$vuetify.theme.themes.dark.appFontColor.base = '#FFFFFF'
    },
}
</script>
