import { auth } from '@/firebase'
import getUserDocument from '@/util/cloudFunctions/userFunctions'

export const actionsAuth = {
    authAction({ commit }) {
        auth.onAuthStateChanged(async (user) => {
            if (user) {
                await setUserDocument(commit, user)
            } else {
                commit('setUser', null)
            }
            commit('setLoading', false)
        })
    },
    setBrandedApp({ commit }) {
        commit('setBrandedApp', true)
    },
    closeCollectUserDetails({ commit }) {
        commit('setCollectUserDetails', false)
    },
    refreshUserDocument({ commit, getters }) {
        const user = getters.getUser
        setUserDocument(commit, user)
    },
}

async function setUserDocument(commit, user) {
    const result = await getUserDocument(user.uid || user.id)

    if (result.success) {
        const userDoc = result.doc
        commit('setUser', userDoc)
    } else {
        // Logged in but cannot find user document, so we need to collect the user details
        // Only happens with external auth
        if (auth.currentUser.providerData[0].providerId !== 'password') {
            commit('setUser', { id: auth.currentUser.uid })
            commit('setCollectUserDetails', true)
        }
    }
}
