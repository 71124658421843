<template>
    <ValidationObserver ref="observer">
        <v-form ref="form">
            <!-- Email Field -->
            <ValidationProvider
                v-slot="{ errors }"
                :name="this.$t('message.email')"
                rules="required|max:64|email"
            >
                <text-trimmer
                    :label="emailTranslation"
                    v-model="email"
                    prepend-icon="mdi-account-outline"
                    :error-messages="errors"
                ></text-trimmer>
            </ValidationProvider>

            <!-- Password Field -->
            <ValidationProvider
                :name="this.$t('message.password')"
                rules="required|max:64"
                v-slot="{ errors }"
            >
                <v-text-field
                    :label="passwordTranslation"
                    v-model="password"
                    prepend-icon="mdi-lock"
                    :error-messages="errors"
                    :append-icon="passwordToggle ? 'mdi-eye-off' : 'mdi-eye'"
                    @click:append="() => (passwordToggle = !passwordToggle)"
                    :type="passwordToggle ? 'password' : 'text'"
                ></v-text-field>
            </ValidationProvider>

            <p class="error--text text-center py-3" v-if="error !== ''">
                {{ error }}
            </p>

            <v-row justify="center" class="mt-3">
                <v-btn
                    class="primary auth-btn"
                    @click="submit"
                    :loading="loading"
                    depressed
                    ref="loginButton"
                >
                    {{ $t('message.login') }}
                </v-btn>
            </v-row>
        </v-form>
    </ValidationObserver>
</template>

<script>
import TextTrimmer from '@/components/shared/TextTrimmer.vue'
import { auth, signInWithEmailAndPassword } from '@/firebase'
import getAuthErrorMessage from '@/util/authErrors'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { AUTHENTICATION_MODULE_NAME } from '@/store/moduleNames'

// Analytics
import { LOGIN, LOGIN_ERROR } from '@/analytics/events'
import { subStr } from '@/analytics/util'
import { mapGetters } from 'vuex'

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        TextTrimmer,
    },
    data() {
        return {
            password: '',
            email: '',
            error: '',
            loading: false,
            passwordToggle: true,
        }
    },
    computed: {
        ...mapGetters({
            isUserAuth: `${AUTHENTICATION_MODULE_NAME}/isUserAuth`,
        }),
        emailTranslation() {
            return this.$t('message.email')
        },
        passwordTranslation() {
            return this.$t('message.password')
        },
    },
    watch: {
        isUserAuth(newVal) {
            if (newVal && this.loading) {
                this.$emit('success')
            }
        },
    },
    methods: {
        async validate() {
            return await this.$refs.observer.validate()
        },
        async submit() {
            this.error = ''
            this.loading = true

            const isValid = await this.validate()

            if (!isValid) {
                this.loading = false
                return
            }

            signInWithEmailAndPassword(auth, this.email, this.password)
                .then(() => {
                    setTimeout(() => {
                        this.$gtag.event(LOGIN)
                    }, 1000)
                })
                .catch((error) => {
                    this.$gtag.event(LOGIN_ERROR, {
                        errorMessage: subStr(error.code),
                    })
                    this.error = getAuthErrorMessage(error.code)
                    this.loading = false
                })
        },
    },
}
</script>
